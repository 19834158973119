class ContactUsPage extends Component {

    static  name() {
        return "ContactUsPage";
    }

    static componentName() {
        return "ContactUsPage";
    }

    getTemplate() {
        return `<div class="static-page">
                    <headerSectionComponent></headerSectionComponent>
                    <div class="section-content container">
                        <div class="section-header text-center">
                            <img src="/static/ecommerce/img/contact_us_banner.jpeg" class="img-fluid" alt="" />
                        </div>
                        <div class="row mt-5">
                            <div class="col">
                                <div id="sugerencias">
                                    <h4 class="section-title">Hacenos sugerencias</h4>
                                    <p>Porque nuestros clientes son parte esencial y fundamental de este ecosistema, queremos escucharlos. ¿Querés proponer nuevos productos?
                                    ¿Querés sugerir cambios en la forma que te atendemos, te entregamos, te hablamos? ¿No te sentís cómodo en alguna instancia cuando comprás 
                                    en nuestra web? Para todos estos temas y muchos más, estamos abiertos a escucharte para mejorar nuestro servicio. Cualquier sugerencia que tengas 
                                    no dudes en escribirnos a:</p>
                                    <a href="mailto:sugerencias@ofisnack.com" target="_blank">sugerencias@ofisnack.com</a>
                                </div>
                                <div id="formaparte" class="mt-3">
                                    <h4 class="section-title">Formá parte del equipo OfiSnack</h4>
                                    <p>Te contamos cómo somos y qué cosas nos gustan. Somos un equipo de personas apasionadas a las que nos encanta dar lo mejor de nosotros a 
                                    nuestros clientes. Nos gusta tener muy buenas ideas, ponernos en lugar de ellos y pensar cómo podemos hacerlos más felices mediante el trabajo que hacemos. 
                                    Nos encanta la creatividad, discutir ideas, fijarnos metas y ejecutarlas.<br>
                                    Nos encantan los resultados y trabajamos todos los días con muchas ganas para lograrlos. Nuestro equipo de trabajo se siente muy cómodo con personas que sean 
                                    excelentes seres humanos, les guste trabajar en equipo, que sean orientados a resultados, apasionados y resilientes, sobre todo.</p>
                                    <p>Si vos te sentís identificado con lo que buscamos, no dudes en ponerte en contacto con nosotros. Envianos tu CV y una breve carta explicándonos por qué te 
                                    gustaría formar parte de nuestro equipo a:</p>
                                    <a href="mailto:rrhh@ofisnack.com" target="_blank">rrhh@ofisnack.com</a>
                                </div>
                                <div id="proveedor" class="mt-3">
                                    <h4 class="section-title">Quiero ser proveedor</h4>
                                    <p>Queremos trabajar con emprendedores enfocados en desarrollar productos saludables, naturales y sustentables. Productores que prioricen la calidad a través 
                                    de la innovación. Valoramos el impacto que sus empresas producen en la comunidad y en el medio ambiente. Seleccionamos a cada proveedor escuchando su historia 
                                    de vida, sus sueños y sus objetivos como emprendedor.</p>
                                    <p>Si vos sentís que tu empresa puede ser parte de lo que buscamos, no dudes en ser partner de OfiSnack. Nuestra empresa busca relaciones honestas, sinceras, 
                                    perdurables a largo plazo. Nuestra fórmula es win-win.<br>
                                    Si tu empresa tiene estos deseos y valores, ponete en contacto con nosotros a través de:</p>
                                    <a href="mailto:proveedores@ofisnack.com" target="_blank">proveedores@ofisnack.com</a>
                                </div>
                                <div id="reclamo" class="mt-3">
                                    <h4 class="section-title">Envianos tu reclamo</h4>
                                    <p>Si estás en esta sección, es porque no estás satisfecho con algo. Abrimos este canal de diálogo inicial para que nos cuentes cuál es el reclamo 
                                    que tenés para hacernos. Nos interesa y nos importa mucho escucharte, sentimos que escuchándote podés hacernos mejores y es esa nuestra premisa.</p>
                                    <p>Por favor, escribinos y danos todos los detalles de tu reclamo. Lo analizaremos y te daremos una respuesta a la brevedad:</p>
                                    <a href="mailto:reclamos@ofisnack.com" target="_blank">reclamos@ofisnack.com</a>
                                </div>
                            </div>
                            <div class="col-md-3 text-center text-md-right mt-3 mt-md-0">
                                <img src="/static/ecommerce/img/contact_us_img_lateral.jpeg" class="img-fluid mb-3" alt="" />
                                <img src="/static/ecommerce/img/contact_us_img_lateral_2.jpeg" class="img-fluid" alt="" />
                                <p class="quote mb-5 text-left">
                                    <span><img src="/static/ecommerce/img/cuote-open-green.png" width="38" class="img-fluid" alt="" /></span>
                                    Nuestra empresa, busca relaciones honestas, sinceras y perdurables a largo plazo.
                                    <span><img src="/static/ecommerce/img/cuote-close-green.png" width="38" class="img-fluid" alt="" /></span>
                                </p>
                                <img src="/static/ecommerce/img/contact_us_img_lateral_3.jpeg" class="img-fluid mb-3" alt="" />
                                <img src="/static/ecommerce/img/contact_us_img_lateral_4.jpeg" class="img-fluid" alt="" />
                            </div>
                        </div>
                    </div>
                    <footerSectionComponent></footerSectionComponent>
                </div>`;
    }
}

ContactUsPage.registerComponent();
